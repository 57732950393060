import React, { useState, useEffect } from "react";
import "./App.css";

const Timer = () => {
  // Timer states for first and second halves
  const [firstHalfSeconds, setFirstHalfSeconds] = useState(0);
  const [firstHalfMilliseconds, setFirstHalfMilliseconds] = useState(0);
  const [secondHalfSeconds, setSecondHalfSeconds] = useState(2700); // Starts at 45:00
  const [secondHalfMilliseconds, setSecondHalfMilliseconds] = useState(0);

  // Extra time states for first and second halves
  const [firstHalfExtraTime, setFirstHalfExtraTime] = useState(0);
  const [firstHalfExtraMilliseconds, setFirstHalfExtraMilliseconds] = useState(0);
  const [secondHalfExtraTime, setSecondHalfExtraTime] = useState(0);
  const [secondHalfExtraMilliseconds, setSecondHalfExtraMilliseconds] = useState(0);

  // Shared states
  const [seconds, setSeconds] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [extraTime, setExtraTime] = useState(0);
  const [extraMilliseconds, setExtraMilliseconds] = useState(0);
  const [isExtraTimeRunning, setIsExtraTimeRunning] = useState(false);
  const [half, setHalf] = useState(1); // Track current half (1 or 2)

  const [heimTeam, setHeimTeam] = useState("");
  const [gastTeam, setGastTeam] = useState("");

  const [heimScore, setHeimScore] = useState(0);
  const [gastScore, setGastScore] = useState(0);

  // State for current date and time
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup on component unmount
  }, []);

  const displayDateTime = (time) => {
    // Options for weekday in German
    const weekdayOptions = { weekday: 'long' };
    const weekday = time.toLocaleDateString('de-DE', weekdayOptions); // Day of the week in German

    // Format the date as dd.MM.yyyy
    const date = time.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });

    // Get the time in hh:mm:ss format
    const timeString = time.toLocaleTimeString('de-DE');

    return `${weekday}, ${date} ${timeString}`;
  };


  // Initialize timer and extra time based on the half
  useEffect(() => {
    if (half === 1) {
      setSeconds(firstHalfSeconds);
      setMilliseconds(firstHalfMilliseconds);
      setExtraTime(firstHalfExtraTime);
      setExtraMilliseconds(firstHalfExtraMilliseconds);
    } else {
      setSeconds(secondHalfSeconds);
      setMilliseconds(secondHalfMilliseconds);
      setExtraTime(secondHalfExtraTime);
      setExtraMilliseconds(secondHalfExtraMilliseconds);
    }
  }, [
    half,
    firstHalfSeconds, firstHalfMilliseconds, firstHalfExtraTime, firstHalfExtraMilliseconds,
    secondHalfSeconds, secondHalfMilliseconds, secondHalfExtraTime, secondHalfExtraMilliseconds
  ]);

  // Start and stop the main timer
  useEffect(() => {
    let interval;
    const maxSeconds = half === 1 ? 2700 : 5400; // 45 min for first half, 90 min for second half
    if (isRunning && seconds < maxSeconds) {
      interval = setInterval(() => {
        setMilliseconds((prev) => {
          if (prev === 99) {
            setSeconds((s) => s + 1);
            return 0;
          }
          return prev + 1;
        });
      }, 10);
    } else if (seconds >= maxSeconds && isRunning) {
      // Stop the main timer when half reaches the end and start extra time
      setIsRunning(false);
      setMilliseconds(0); // Stop milliseconds at 00
      setIsExtraTimeRunning(true);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, seconds, half]);

  // Start and stop extra time
  useEffect(() => {
    let interval;
    if (isExtraTimeRunning) {
      interval = setInterval(() => {
        setExtraMilliseconds((prev) => {
          if (prev === 99) {
            setExtraTime((et) => et + 1);
            return 0;
          }
          return prev + 1;
        });
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isExtraTimeRunning]);

  // Debug: Set timer to 44:50 or 89:50 based on the half
  useEffect(() => {
    const handleDebugHotkey = (e) => {
      // Check for Alt + Shift + D for first half
      if (e.shiftKey && e.key === "D") {
        if (half === 1) {
          setSeconds(2695); // Set to 44 minutes and 50 seconds for first half
          setMilliseconds(0);
          setIsRunning(true);
        }
      }

      // Check for Alt + Shift + F for second half
      if (e.shiftKey && e.key === "F") {
        if (half === 2) {
          setSeconds(5395); // Set to 89 minutes and 55 seconds for second half
          setMilliseconds(0);
          setIsRunning(true);
        }
      }
    };

    window.addEventListener("keydown", handleDebugHotkey);

    return () => {
      window.removeEventListener("keydown", handleDebugHotkey);
    };
  }, [half]);


  // Save current timer and extra time state when switching halves
  const switchHalf = (selectedHalf) => {
    if (half === 1) {
      setFirstHalfSeconds(seconds);
      setFirstHalfMilliseconds(milliseconds);
      setFirstHalfExtraTime(extraTime);
      setFirstHalfExtraMilliseconds(extraMilliseconds);
    } else if (half === 2) {
      setSecondHalfSeconds(seconds);
      setSecondHalfMilliseconds(milliseconds);
      setSecondHalfExtraTime(extraTime);
      setSecondHalfExtraMilliseconds(extraMilliseconds);
    }

    // Automatically stop the current running timer when switching halves
    setIsRunning(false);
    setIsExtraTimeRunning(false);

    setHalf(selectedHalf);
  };

  const resetTimer = () => {
    if (half === 1) {
      setFirstHalfSeconds(0);
      setFirstHalfMilliseconds(0);
      setFirstHalfExtraTime(0);
      setFirstHalfExtraMilliseconds(0);
    } else {
      setSecondHalfSeconds(2700); // Second half starts at 45:00
      setSecondHalfMilliseconds(0);
      setSecondHalfExtraTime(0);
      setSecondHalfExtraMilliseconds(0);
    }
    setSeconds(half === 1 ? 0 : 2700); // Reset to 0 for first half, 45:00 for second half
    setMilliseconds(0);
    setExtraTime(0);
    setExtraMilliseconds(0);
    setIsRunning(false);
    setIsExtraTimeRunning(false);
  };

  const resetAll = () => {
    // Reset first half timers and extra time
    setFirstHalfSeconds(0);
    setFirstHalfMilliseconds(0);
    setFirstHalfExtraTime(0);
    setFirstHalfExtraMilliseconds(0);

    // Reset second half timers and extra time
    setSecondHalfSeconds(2700); // Second half starts at 45:00
    setSecondHalfMilliseconds(0);
    setSecondHalfExtraTime(0);
    setSecondHalfExtraMilliseconds(0);

    // Reset current timers and extra time
    setSeconds(0); // Reset to 0 for first half
    setMilliseconds(0);
    setExtraTime(0);
    setExtraMilliseconds(0);

    // Reset the team names and scores
    setHeimTeam("");
    setGastTeam("");
    setHeimScore(0);
    setGastScore(0);

    // Set back to the first half by default
    setHalf(1);

    // Stop the timers
    setIsRunning(false);
    setIsExtraTimeRunning(false);
  };

  const displayTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-yellow-500 font-sans">
      {/* Current Date and Time */}
      <div className="absolute top-4 text-yellow-500 text-xl text-center font-bold">
        {displayDateTime(currentTime)}
      </div>

      {/* Team Names Section */}
      <div className="absolute top-8 left-8 p-4">
        <div className="text-yellow-500 text-6xl lg:text-6xl sm:text-3xl font-bold">HEIM</div>
        <input
          type="text"
          value={heimTeam}
          onChange={(e) => setHeimTeam(e.target.value)}
          placeholder="Enter team name"
          className="mt-2 p-2 rounded-lg bg-gray-800 text-white text-6xl lg:text-5xl sm:text-xl"
        />

        {/* Counter for HEIM */}
        <div className="flex items-center justify-center mt-4 space-x-4">
          <button
            className="bg-yellow-500 hover:bg-yellow-700 text-black font-bold py-4 px-6 lg:py-4 lg:px-6 sm:py-2 sm:px-3 text-7xl lg:text-7xl sm:text-lg rounded-l-lg"
            onClick={() => setHeimScore((prev) => Math.max(0, prev - 1))}
          >
            -
          </button>
          <span className="bg-gray-800 text-white font-bold py-4 px-6 lg:py-4 lg:px-6 sm:py-2 sm:px-3 text-4xl lg:text-7xl sm:text-lg">{heimScore}</span>
          <button
            className="bg-yellow-500 hover:bg-yellow-700 text-black font-bold py-4 px-6 lg:py-4 lg:px-6 sm:py-2 sm:px-3 text-8xl lg:text-7xl sm:text-lg rounded-r-lg"
            onClick={() => setHeimScore((prev) => prev + 1)}
          >
            +
          </button>
        </div>
      </div>

      <div className="absolute top-8 right-8 p-4">
        <div className="text-yellow-500 text-6xl lg:text-6xl sm:text-3xl font-bold">GAST</div>
        <input
          type="text"
          value={gastTeam}
          onChange={(e) => setGastTeam(e.target.value)}
          placeholder="Enter team name"
          className="mt-2 p-2 rounded-lg bg-gray-800 text-white text-4xl lg:text-5xl sm:text-xl"
        />

        {/* Counter for GAST */}
        <div className="flex items-center justify-center mt-4 space-x-4">
          <button
            className="bg-yellow-500 hover:bg-yellow-700 text-black font-bold py-4 px-6 lg:py-4 lg:px-6 sm:py-2 sm:px-3 text-4xl lg:text-7xl sm:text-lg rounded-l-lg"
            onClick={() => setGastScore((prev) => Math.max(0, prev - 1))}
          >
            -
          </button>
          <span className="bg-gray-800 text-white font-bold py-4 px-6 lg:py-4 lg:px-6 sm:py-2 sm:px-3 text-4xl lg:text-7xl sm:text-lg">{gastScore}</span>
          <button
            className="bg-yellow-500 hover:bg-yellow-700 text-black font-bold py-4 px-6 lg:py-4 lg:px-6 sm:py-2 sm:px-3 text-4xl lg:text-7xl sm:text-lg rounded-r-lg"
            onClick={() => setGastScore((prev) => prev + 1)}
          >
            +
          </button>
        </div>
      </div>


      {/* Half Selection Menu */}
      <div className="flex justify-center space-x-4 mb-6">
        <button
          className={`${half === 1 ? "bg-yellow-500 text-black" : "bg-gray-700 text-white"
            } py-2 px-4 rounded-lg text-xl transition duration-300`}
          onClick={() => switchHalf(1)}
        >
          1
        </button>
        <button
          className={`${half === 2 ? "bg-yellow-500 text-black" : "bg-gray-700 text-white"
            } py-2 px-4 rounded-lg text-xl transition duration-300`}
          onClick={() => switchHalf(2)}
        >
          2
        </button>
      </div>

      {/* Timer Display */}
      <div className="flex flex-col items-center">
        <h1 className="text-9xl lg:text-9xl sm:text-5xl font-bold">
          {displayTime(seconds)}
          <span className="text-5xl lg:text-5xl sm:text-3xl align-super ml-2">
            .{seconds >= (half === 1 ? 2700 : 5400) ? "00" : String(milliseconds).padStart(2, "0")}
          </span>
        </h1>
        <p className="text-2xl lg:text-2xl sm:text-lg mt-4 text-gray-400">
          + {displayTime(extraTime)}.<span>{String(extraMilliseconds).padStart(2, "0")}</span> Nachspielzeit
        </p>
      </div>

      {/* Control Buttons */}
      <div className="mt-8 flex gap-6">
        <button
          className={`${isRunning || isExtraTimeRunning
            ? "bg-red-500 hover:bg-red-700"
            : "bg-green-500 hover:bg-green-700"
            } text-white py-2 px-8 lg:py-2 lg:px-8 sm:py-1 sm:px-4 rounded text-2xl lg:text-2xl sm:text-sm`}
          onClick={() =>
            isExtraTimeRunning ? setIsExtraTimeRunning(false) : setIsRunning(!isRunning)
          }
        >
          {isRunning || isExtraTimeRunning ? "Stop" : "Start"}
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-8 lg:py-2 lg:px-8 sm:py-1 sm:px-4 rounded text-2xl lg:text-2xl sm:text-sm"
          onClick={resetTimer}
        >
          Reset
        </button>
        <button
          className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-8 lg:py-2 lg:px-8 sm:py-1 sm:px-4 rounded text-2xl lg:text-2xl sm:text-sm"
          onClick={resetAll}
        >
          Reset All
        </button>
      </div>

      {/* Copyright */}
      <div className="absolute bottom-4 left-4 text-sm text-yellow-400">
        © 2024 Gabriele Ferrali
      </div>
    </div>
  );
};

export default Timer;