import './App.css';
import Timer from './Timer';

function App() {
  return (
   <Timer />
  );
}

export default App;
